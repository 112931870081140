import { ModalProvider } from "../../features/auth/Modals/useModalContext";
import { AuthContextProvider } from "../../features/auth/AuthContext/AuthContext";
import { SearchContextProvider } from "../../pages/search/SearchContextProvider";
import { SignalRContextProvider } from "../../utils/SignalRContextProviders";
import { ModalContextProvider } from "../../features/UI/Modal/ModalContextProvider";
import { NotificationContextProvider } from "../../features/UI/Notification/NotificationContextProvider";
import { ShopCartContextProvider } from "../../entities/cart/model/ShopCartContextProvider";

export const withContextProviders = (children: React.ReactElement) => (
    <SignalRContextProvider>
        <ModalProvider>
            <ModalContextProvider>
                <NotificationContextProvider>
                    <AuthContextProvider>
                        <SearchContextProvider>
                            <ShopCartContextProvider>{children}</ShopCartContextProvider>
                        </SearchContextProvider>
                    </AuthContextProvider>
                </NotificationContextProvider>
            </ModalContextProvider>
        </ModalProvider>
    </SignalRContextProvider>
);
