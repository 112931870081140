import clsx from "clsx";
import styles from "./AuthContext.module.scss";
import { createContext, PropsWithChildren, useState } from "react";
import Dialog from "../Dialogs/Dialog";
import { useLocation, useNavigate } from "react-router-dom";
import Button, { ButtonVariant } from "../../../shared/ui/buttons/Button/Button";
import Loader from "../../../shared/ui/Loader/Loader";
import BrinxTVLogo from "../../../assets/Common/main-brinxlogo-second.svg";
import { useAppSelector } from "../../../app/hooks";
import { selectAccessToken } from "../../../shared/lib/authSlice";

interface IAuthContext {
    navigateProtected: (path: string) => void;
    closeDialog: () => void;
    openDialog: (redirectToAfterLogin?: string) => void;
    turnOnLoader: () => void;
    turnOffLoader: () => void;
}

export const AuthContext = createContext<IAuthContext>({
    navigateProtected: (path) => console.warn("navigateProtected is not provided"),
    closeDialog: () => console.warn("closeDialog was not provided"),
    openDialog: () => console.warn("openDialog was not provided"),
    turnOnLoader: () => console.warn("turnOnLoader was not provided"),
    turnOffLoader: () => console.warn("turnOffLoader was not provided"),
});

export const AuthContextProvider = (props: PropsWithChildren) => {
    const [authProps, setAuthProps] = useState<{
        isShowLoginDialog: boolean;
        path?: string;
    }>({ isShowLoginDialog: false });
    const [isShowLoader, setIsShowLoader] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();
    const accessToken = useAppSelector(selectAccessToken);
    const navigateProtected = (path: string) => {
        if (accessToken) {
            navigate(path);
        } else {
            openDialog(path);
        }
    };
    const closeDialog = () => {
        document.body.classList.remove("no-scroll");
        setAuthProps({ isShowLoginDialog: false });
    };

    const openDialog = (redirectToAfterLogin: string | undefined) => {
        if (redirectToAfterLogin) localStorage.setItem("triedAccess", redirectToAfterLogin);
        document.body.classList.add("no-scroll");
        setAuthProps({ isShowLoginDialog: true, path: redirectToAfterLogin });
    };

    const turnOnLoader = () => {
        document.body.classList.add("no-scroll");
        setIsShowLoader(true);
    };

    const turnOffLoader = () => {
        document.body.classList.remove("no-scroll");
        setIsShowLoader(false);
    };

    return (
        <AuthContext.Provider
            value={{
                navigateProtected,
                closeDialog,
                openDialog,
                turnOnLoader,
                turnOffLoader,
            }}
        >
            <Dialog open={authProps.isShowLoginDialog} onClose={closeDialog}>
                <div className={styles.wrapper}>
                    <div className={styles.logo}>
                        <img src={BrinxTVLogo} alt="brinx logo" />
                    </div>
                    <div className={styles.content}>
                        <p className={styles.title}>the kingdom of</p>
                        <p className={styles.awesomeness}>awesomeness</p>
                        <div className={styles.registration}>
                            <p className={styles.registrationTitle}>EARN 1,000 Brinx Bux</p>
                            <p className={styles.registrationTitle}>Instantly!</p>
                            <p className={styles.text}>Get free & discounted merch</p>
                            <Button
                                className={styles.btn}
                                title="Register now"
                                buttonVariant={ButtonVariant.Black}
                                onClick={() => {
                                    closeDialog();
                                    location.pathname.startsWith("/registration")
                                        ? navigate(0)
                                        : navigate("/registration");
                                }}
                            />
                        </div>
                        <div className={styles.signIn}>
                            <p className={styles.signInTitle}>Already a user?</p>
                            <Button
                                className={clsx(styles.btn, styles.signInButton)}
                                title="Sign In now"
                                buttonVariant={ButtonVariant.Yellow}
                                onClick={() => {
                                    closeDialog();
                                    location.pathname.startsWith("/sign-in") ? navigate(0) : navigate("/sign-in");
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
            <div className={clsx(styles.loader, isShowLoader ? undefined : styles.hidden)}>
                <Loader />
            </div>
            {props.children}
        </AuthContext.Provider>
    );
};
