import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { store } from "../../app/store/store";
import { Capacitor } from "@capacitor/core";
import { authSliceReducers } from "../lib/authSlice";
import { TokenResponse } from "../../features/auth/authApiSlice";
import { Mutex } from "async-mutex";

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACK_URL,
    prepareHeaders: (headers) => {
        const token = store.getState().auth.accessToken;
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        if (Capacitor.isNativePlatform()) {
            headers.set("app-version", process.env.REACT_APP_BACK_VERSION ?? "");
        }

        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const refreshResult = await baseQuery(
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        url: `${process.env.REACT_APP_BACK_URL}/auth/token/refresh`,
                        body: JSON.stringify({
                            accessToken: localStorage.getItem("AccessToken") ?? "",
                            refreshToken: localStorage.getItem("RefreshToken") ?? "",
                        }),
                    },
                    api,
                    extraOptions,
                );

                if (refreshResult.data) {
                    const tokenResponse = refreshResult.data as TokenResponse;
                    api.dispatch(
                        authSliceReducers.tokenReceived({
                            accessToken: tokenResponse.accessToken,
                            refreshToken: tokenResponse.refreshToken,
                        }),
                    );
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    api.dispatch(authSliceReducers.loggedOut());
                }
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }
    return result;
};

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: baseQueryWithReauth,
    refetchOnReconnect: true,
    endpoints: () => ({}),
    tagTypes: [
        "StreamChannels",
        "ShowChannels",
        "ShowChannelInfo",
        "VideoInfo",
        "Videos",
        "SubscriptionAccessToken",
        "Categories",

        "Authorize",
        "Admins",

        "ShopPage-Collections",
        "ShopPage-Collections-ProductsOrder",

        "CurrentUser",
        "Auctions",
        "BrinxBux",
        "BrinxBux-Settings",
        "Products",
        "CartProducts",
        "BettingItems",
        "BettingBets",
        "Redeem-Products",
        "RedeemCollections",

        "Notifications",
        "NotificationsAdmin",

        "SweepstakesViralsweep",
        "Sweepstakes",
        "SweepstakeEntries",

        "News",
        "Article",
        "NewsCategories",

        "PpvSubscriptionInfo",
        "ShippingInfo",
        "HasPpvSubscription",

        "UserStreamReservation",
        "UserStreamIsLiveCheck",

        "ChatUserBan",
        "UserBanList",
        "BanWords",

        "AffiliateBrands",
        "UserAffiliateBrands",
        "AffiliateCommissionInfo",

        "PageSettings",
        "PageInfos",
        "ScheduledPageSettings",
        "CurrentPageSettings",

        "PageEventSchedulers",

        "PageSettingsMagicWidgets",
        "PageSettingsSpecificMagicWidgets",
        "PageSettingsShopMagicWidgets",
        "PageSettingsRaiseYourHandMagicWidgets",
        "PageSettingsShopifyAuctionMagicWidgets",
        "PageSettingsBettingMagicWidgets",
        "PageSettingsSweepstakeMagicWidgets",
        "PageSettingsChatMagicWidgets",
        "PageSettingsRedeemMagicWidgets",

        "PageSettingsEventActions",
        "AppSettings",

        "WatchTimeProcessingStatus",
        "SyncPlaylistsStatus",
        "ResetHistory",

        "CompetitionLeaderboard",
        "Competitions",
        "CompetitionParticipants",
        "ShopShippingInfo",

        "EmailVerification",
    ],
});
